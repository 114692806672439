
  import { inject, ref, defineComponent } from "vue";
  import { apiPost, apiDelete } from "@/api";
  import { ShowSuccess, Toast } from "@/utils/notify";
  import EditableField from "@/components/EditableField.vue";
  
  export default defineComponent({
    name: "P2PExchangeRow",
    components: { EditableField },
    emits: ["updated", "delete"],
    props: {
      dt: {
        type: Object,
        required: true,
      },
    },
    setup(props, context) {
      const RequestConfirmation = inject("RequestConfirmation");
  
      const loading = ref(false);
  
      const ToggleAdmin = async () => {
        loading.value = true;
        try {
          await apiPost(
            `/admin/user-account/${
              props.dt.type === "BASIC_USER" ? "add-admin" : "remove-admin"
            }`,
            {
              userId: props.dt.id,
            }
          );
          // eslint-disable-next-line vue/no-mutating-props
          props.dt.type =
            props.dt.type === "BASIC_USER" ? "ADMIN_USER" : "BASIC_USER";
          ShowSuccess();
        } catch (e:any) {
          Toast.fire({
            icon: "warning",
            title: "Unable to toggle admin type: " + e.message,
          }).then();
        }
        loading.value = false;
      };

      const DeleteExchangeRate = async () => { 
        loading.value = true;
        try {
          await apiDelete(`/admin/currency-exchange/${props.dt.id}`);
          ShowSuccess();
          context.emit("delete");
        } catch (e: any) {
          Toast.fire({
            icon: "warning",
            title: "Unable to delete exchange rate: " + e.message,
          }).then();
        }
        loading.value = false;
      };

  
      return {
        loading,
        RequestConfirmation,
        ToggleAdmin,
        DeleteExchangeRate
      };
    },
  });
  