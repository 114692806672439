
  import HourglassLoader from "@/components/loader/HourglassLoader.vue";
  import CountSelector from "@/components/mini/CountSelector.vue";
  import { onBeforeMount, ref, defineComponent } from "vue";
  import { apiGet } from "@/api";
  import { useRoute } from "vue-router";
  import { Toast } from "@/utils/notify";
  import P2PExchangeRow from "@/views/currency/constituent/P2PExchangeRow.vue";
  
  export default defineComponent({
    name: "P2PExchangeRates",
    components: {
      HourglassLoader,
      CountSelector,
      P2PExchangeRow
    },
    setup() {
      const loading = ref(false);
      const data = ref(null);
  
      const route = useRoute();
  
      const RefreshData = async () => {
        loading.value = true;
        try {
          const response = await apiGet(
            `/admin/currency-exchange`,
            (data.value as any)?.meta ?? {}
          );
          console.log(response.data)
          data.value = response.data.data;
          Toast.fire({
            icon: "success",
            title: "Currency exchange settings fetched successfully",
          }).then();
        } catch (e:any) {
          Toast.fire({
            icon: "warning",
            title: "Unable to fetch data: " + e.message,
          }).then();
        }
        loading.value = false;
      };
  
      onBeforeMount(RefreshData);
  
      return {
        RefreshData,
        route,
        data,
        loading,
      };
    },
  });
  